import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Title from "../elements/Title";
import PageTitle from "../elements/PageTitle";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ArticleImg = ({ children, src, position, size }) => {
  let cl = "h-full w-full";

  switch (size) {
    case "small":
      cl = "h-auto w-[25%]";
      break;
    case "medium":
      cl = "h-auto w-[50%]";
      break;
    case "big":
      cl = "h-full w-full";
      break;
    default:
      cl = "h-full w-full";
      break;
  }

  const isRightPos = position === "right";
  const isBigSize = size === "big";

  return (
    <div
      className={`mb-4 gap-5 ${isBigSize ? "inline-block" : "inline-flex"} ${
        isRightPos && "flex-row-reverse"
      }`}
    >
      <GatsbyImage
        image={getImage(src)}
        className={`h-full w-full ${cl} object-cover`}
        alt=""
      />
      {children && (
        <div className={`inline-block ${isBigSize && "mt-5"}`}>{children}</div>
      )}
    </div>
  );
};

const SingleArticleSection = ({ title, body, localImages }) => (
  <section className="bg-white">
    <PageTitle pageTitle={title} />
    {/* Header */}
    <Title title={title} />

    <div className="relative bg-white mt-12 overflow-hidden article">
      {/* Content */}
      <div className="relative px-8 sm:px-12 lg:px-64 xl:px-80">
        <div className="text-lg prose prose-violet prose-lg text-gray-500 mx-auto">
          <MDXProvider components={{ ArticleImg }}>
            <MDXRenderer localImages={localImages}>
              {/* Content of article */}
              {body}
            </MDXRenderer>
          </MDXProvider>
        </div>
      </div>
    </div>
  </section>
);

export default SingleArticleSection;
